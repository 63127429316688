import React from 'react';
import { Contact_Lead_Status } from './../../../Util/Constants';

const ContactTopNavBar = ({
  users,
  assessmentTeamLeader,
  contactName,
  handleInputChange,
  userData,
}) => {
  const findNameById = (id, array) => {
    const item = array.find((item) => item.id === id);
    return item ? item.name : '';
  };

  const assessmentTeamName = findNameById(assessmentTeamLeader, users);

  return (
    <nav className="flex flex-row-reverse items-center justify-between bg-sky-200 p-4 shadow-sm">
      <div className="flex items-center space-x-4 gap-2">
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="contactLeadStatus"
            className="text-sm font-medium text-dark"
          >
            Contact Lead Status:
          </label>
          <select
            id="contactLeadStatus"
            className="input input-bordered text-gray-700 border-gray-300 hover:border-gray-400"
            value={userData?.contactLeadStatus}
            onChange={(e) =>
              handleInputChange({
                target: { name: 'contactLeadStatus', value: e.target.value },
              })
            }
          >
            {Contact_Lead_Status.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="ml-4">
          <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-800">
              Business / Contact Name:
            </span>{' '}
            {contactName}
          </p>
      
          <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-800">
              Team Leader:
            </span>{' '}
            {assessmentTeamName}
          </p>
          {/* <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-600">
              Assessment:
            </span>{' '}
            {assessmentTeamName}
          </p> */}
        </div>

 
      </div>
    </nav>
  );
};

export default ContactTopNavBar;
