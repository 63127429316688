import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Divider,
  Grid,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { MUI_LICENSE_KEY } from '../../../../Util/Constants';
import { LicenseInfo, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import React, { useEffect, useState } from 'react';
import Agent from '../../../../api/agent';
import { Countries, getDateDifference } from '../../../../Util/Util';
import { connect } from 'react-redux';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Chat from '../../../Common/Chat/chat';
import ArchiveIcon from '@mui/icons-material/Archive';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const WhatsappCountry = ({ country }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState();
  const [showChat, setShowChat] = useState();
  //const [status, setStatus] = useState('ongoing');
  const [loaded, setLoaded] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [reload, setReload] = useState(false);
  const [templates, setTemplates] = useState([]);
  //console.log(country)

  const countryId = Countries.find((c) => c.name == country).id;


  useEffect(() => {
    // console.log('test c',countryId)
    Agent.Chat.getPublicChats(
      showArchived ? 'archived' : 'ongoing',
      countryId == 76 ? 0 : countryId
    
    )
      .then((data) => {
        console.log(data);
        setMessages(data);
        setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  }, [showArchived, reload]);




  useEffect(() => {
    const countryId = Countries.find((c) => c.name == country).id;
    if (countryId) {
      Agent.Communication.getWhatsappTemplateswithCountryId(countryId)
        .then((response) => {
          setTemplates(response);
          console.log('check temp',response)
        })
        .catch((error) => {
          console.error('Error fetching WhatsApp templates:', error);
        });
    }
  }, []);




  let userColumns = [
    // {
    //   field: 'fromNumber',
    //   headerName: 'Mobile Number',
    //   width: 200,
    // },
    {
      field: 'sentBy',
      headerName: 'Sender',
      width: 400,
    },
    {
      field: 'respondedOn',
      headerName: 'Sender Response',
      width: 230,
      renderCell: (params) => (
        <Typography>
          {' '}
          {`(${getDateDifference(params.row.respondedOn, false)})`}
        </Typography>
      ),
    },
    {
      field: 'sentOn',
      headerName: 'TCL Response',
      width: 230,
      renderCell: (params) => (
        <Typography>
          {' '}
          {`${
            params.row.lastAdminAppUserId
              ? '(' + getDateDifference(params.row.sentOn, false) + ')'
              : ''
          }`}
        </Typography>
      ),
    },
    {
      field: 'responded',
      headerName: 'Response Status',
      width: 220,
      renderCell: (params) => (
        <Typography color={params.row.responded ? 'secondary' : 'primary'}>
          {' '}
          {`(${
            params.row.responded ? 'Awaiting TCL Response' : 'TCL Responded'
          })`}
        </Typography>
      ),
    },
    {
      field: 'wamId',
      headerName: 'Chat',
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => openChat(params.row)} size="large">
          <WhatsAppIcon color="primary" />
        </IconButton>
      ),
    },
  ];

  const openChat = (row) => {
    setMessage(row);
    setShowChat(true);
  };
  const closeChat = () => {
    setShowChat(false);
  };
  const updateStatus = (row, newStatus) => {
    Agent.Chat.archiveMessage({
      fromNumber: row.fromNumber,
      status: newStatus,
    })
      .then(() => {})
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const handleArchive = (message) => {
    if (message.messageDealingStatus == 'ongoing') {
      if (
        window.confirm(
          `Are you sure you want to ${
            message.messageDealingStatus == 'ongoing'
              ? 'archive this chat'
              : 'convert this chat to Live'
          }`
        )
      ) {
        updateStatus(message, 'archived');
        closeChat();
      }
    } else {
      updateStatus(message, 'ongoing');
      closeChat();
    }
  };

  return (
    <>
      <Grid container direction={'column'}>
        <Grid item container justifyContent={'flex-end'} direction={'row'}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={showArchived}
                  onChange={(e) => setShowArchived(e.target.checked)}
                  name="Status"
                />
              }
              label={showArchived ? 'Show Live Chats' : 'Show Archived Chats'}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              // startIcon={<EmailIcon />}
              onClick={() => setReload((prev) => !prev)}
            >
              Refresh Chat List
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          {loaded && messages && messages.length == 0 ? (
            <Typography>{`There is no data at the moment for ${country}`}</Typography>
          ) : loaded && messages.length > 0 ? (
            <>
              <div style={{ height: 300, width: '100%' }}>
                <DataGridPro
                  getRowId={(row) => row.wamId}
                  density="compact"
                  autoHeight
                  disableSelectionOnClick
                  disableColumnSelector
                  disableColumnMenu
                  disableColumnFilter
                  disableDensitySelector
                  rows={messages}
                  columns={userColumns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </div>
              {message && (
                <div>
                  <BootstrapDialog
                    onClose={closeChat}
                    aria-labelledby="draggable-dialog-title"
                    open={showChat}
                    maxWidth="lg"
                    fullWidth
                    // PaperComponent={PaperComponent}
                  >
                    <BootstrapDialogTitle
                      onClose={closeChat}
                      style={{ cursor: 'move' }}
                    >
                      <Typography align="center" color="secondary" variant="h5">
                        {`${message.sentBy} (${message.fromNumber}) ${
                          message.messageDealingStatus == 'archived'
                            ? '- ARCHIVED'
                            : ''
                        }`}
                      </Typography>
                      <Divider />
                    </BootstrapDialogTitle>
                    <DialogContent style={{ marginTop: -20 }}>
                      <Chat
                       templates={templates}
                        recordId={0}
                        recipientName={message.sentBy}
                        phone={`+${message.fromNumber}`}
                        type="public"
                        country={message.country||countryId}
                    
                      />
                    </DialogContent>
                    <DialogActions>
                      <Grid container>
                        <Grid item xs={12} marginBottom={1}>
                          <Button
                            className="bg-sky-400 hover:bg-sky-500"
                            variant="contained"
                            size="small"
                            // style={{ fontWeight: 'light', fontSize: '0.2rem' }} // optional: add extra styles
                            startIcon={<ArchiveIcon />}
                            onClick={() => handleArchive(message)}
                          >
                            {message.messageDealingStatus == 'ongoing'
                              ? 'Archive this message'
                              : 'Convert to Live'}
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </BootstrapDialog>
                </div>
              )}
            </>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
  };
};

export default connect(mapStateToProps, null)(WhatsappCountry);
