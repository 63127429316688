/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { MUI_LICENSE_KEY } from '../../../Util/Constants';
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import LoadingView from '../../Common/Loading/LoadingView';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import CourseDetails from './Course/CourseDetails';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import AddCourses from './AddCourses';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Courses = ({ courses, loadCourses, departments, loadDepts }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [course, setCourse] = useState();
  const [name, setName] = useState('');
  const [courseRows, setCourseRows] = useState([]);
  const pageSize = 50;
  const [showAll, setShowAll] = useState(false);

  const resetFilter = () => {
    setCourseRows(courses);
    setName('');
  };
  const applyFilter = () => {
    setCourseRows(
      courses.filter((item) =>
        item.name
          .replace(' ', '')
          .toLowerCase()
          .includes(name.replace(' ', '').toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (departments.length == 0) {
      loadDepts()
        .then((list) => {})
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      //
    }
  }, [departments]);

  useEffect(() => {
    if (courses.length == 0) {
      loadCourses(showAll)
        .then((list) => {
          setCourseRows(list);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          debugger;
        });
    } else {
      setCourseRows(courses);
      setLoading(false);
    }
  }, [courses]);

  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 85,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Course Name',
      width: 450,
      renderCell: (params) => (
        <MyTooltip title={params.row.name}>
          <Button
            // variant="standard"
            style={{ textTransform: 'none' }}
            // as={Button}
            onClick={() => {
              setCourse(params.row);
              setEditMode(true);
            }}
          >
            {params.row.name}
          </Button>
        </MyTooltip>
      ),
    },
    {
      field: 'departmentId',
      headerName: 'Department',
      width: 325,
      renderCell: (params) => (
        <Typography>
          {params.row.departmentId > 0
            ? departments.find(({ id }) => id == params.row.departmentId).name
            : ''}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 120,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <Typography>
            {params.row.description ? (
              <div className="flex justify-center text-green-500 pr-6">
                <CheckCircleIcon className=" w-full h-5 " size={'21px'} />
              </div>
            ) : (
              ''
            )}
          </Typography>
        </div>
      ),
    },
    {
      field: 'duration',
      headerName: 'Course Duration',
      width: 120,
      renderCell: (params) => (
        <div className="text-lg text-center w-full ">
          {params.row.duration > 0
            ? `${params.row.duration} ${
                params.row.duration == 1 ? 'Year' : 'Years'
              }`
            : ''}
        </div>
      ),
    },
    {
      field: 'isTop',
      headerName: 'Marked Top',
      width: 120,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <Typography>
            {params.row.isTop ? (
              <div className="flex justify-center text-green-500 pr-6">
                <CheckCircleIcon className=" w-full h-5 " size={'21px'} />
              </div>
            ) : (
              ''
            )}
          </Typography>
        </div>
      ),
    },

    {
      field: 'isInactive',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row.isInactive ? 'Not Active' : ''}</Typography>
      ),
    },
  ];
  //Countries.find(({ id }) => id === agent.country).name
  return loading ? (
    <LoadingView />
  ) : (
    <div>
      {editMode && <CourseDetails course={course} setAddMode={setEditMode} />}
      {addMode && <AddCourses setAddMode={setAddMode} />}
      <Grid
        container
        direction="column"
        spacing={1}
        style={{ backgroundColor: 'white' }}
      >
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Button
              LinkComponent
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
              onClick={() => navigate('/departments')}
            >
              Department/Subject Management
            </Button>
          </Grid>
          <Grid item>
            <Button
              LinkComponent
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
              startIcon={<AddIcon />}
              onClick={() => setAddMode(true)}
            >
              Add New Course
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Course Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={applyFilter}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={resetFilter}
              className="bg-rose-200 hover:bg-rose-300 text-gray-600 hover:text-gray-700"
            >
              Reset Filters
            </Button>
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAll}
                  onChange={() => {
                    loadCourses(!showAll);
                    setShowAll((prev) => !prev);
                  }}
                  name="checked"
                />
              }
              label="Include Inactive Courses"
            />
          </Grid>
        </Grid>

        <Grid item>
          <DataGridPro
            disableSelectionOnClick
            rows={courseRows}
            pagination
            pageSize={pageSize}
            columns={columns}
            loading={loading}
            disableColumnFilter
            autoHeight
            density="compact"
            sortModel={[{ field: 'name', sort: 'asc' }]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    departments: state.departments,
    courses: state.courses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCourses: (showAll) => dispatch(actions.loadCourses(showAll)),
    loadDepts: () => dispatch(actions.loadDepts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
