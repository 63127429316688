import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import UserList from './UserList'
import {Button, Grid} from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import {useNavigate, useParams} from 'react-router-dom'
import * as actions from '../../store/actions/index'

const Users = ({users, loadUsers, isInternal, role}) => {
  const navigate = useNavigate()
  const {load} = useParams('load')

  useEffect(() => {
    if (users.length === 0 || load === 'load') {
      loadUsers().catch((error) => {
        console.error(error)
      })
    }
  }, [loadUsers, users.length, load])

  return (
    <Grid container direction='column' className='h-full w-full'>
      <Grid item container justifyContent='flex-end' className='mb-4'>
     
      
        <Button
  disabled={!isInternal || role.replaceAll(' ', '') === 'ApplicationTeam'}
  className="btn btn-primary"
  startIcon={<PersonAddIcon />}
  onClick={() => navigate('/register')}
>
  Add User / Agent
</Button>
      
      </Grid>
      <Grid item className='w-full'>
        <UserList />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    isInternal: state.auth.isInternal,
    role: state.auth.roleName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
